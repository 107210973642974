<script>
import i18n from "../i18n";
import {userService} from "@/helpers/fakebackend/user.service";
import {globalVariable} from "@/helpers/global-variables";

export default {
  data() {
    return {
      user : {
        name: ""
      },
      languages: [
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French"
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish"
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese"
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic"
        }
      ],
      current_language: "en",
      globalVariable: globalVariable,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user_infos"));
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
    async logout(e){
      e.preventDefault();
      await userService.logout(true);
      // TODO: Logout from keycloak it's suck now. Maybe because of server
      if (this.$keycloak)
        await this.$keycloak.logout();
      else
        window.location.reload();
    },
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button
            @click="toggleMenu"
            type="button"
            class="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>
        <!-- LOGO -->
        <div class="navbar-brand-box">

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="35" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.svg" alt height="35" />
            </span>
          </a>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <i class="ri-user-3-fill" style="font-size: 17px"></i>
            <span class="d-none d-xl-inline-block ml-1" v-if="user && user.name">{{ user.name }}</span>
            <span class="d-none d-xl-inline-block ml-1" v-if="user && !user.name">{{ user.login }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a v-bind:href="globalVariable.keycloak.PROFILE_URL" target="_blank" class="dropdown-item">
            {{ $t('navbar.dropdown.kevin.list.profile') }}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout" v-on:click="logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.kevin.list.logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
@media screen and (min-width: 769px) {
  #vertical-menu-btn{
    display: none;
  }
}
</style>
